.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2rem;
}
@media screen and (max-width: 850px) {
  .container {
    width: 100%;
  }
}
.titles {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2rem;
}
.titleSubtitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
}
.richElement {
  overflow-wrap: break-word;
}
.richElement ul {
  padding: revert;
}
