.visibleOnDesktop {
  display: block;
  position: sticky;
  top: 0;
  z-index: 15;
}

.visibleOnMobile {
  display: none;
}

@media (max-width: 850px) {
  .visibleOnDesktop {
    display: none;
  }

  .visibleOnMobile {
    display: block;
    display: block;
    position: sticky;
    top: 0;
    z-index: 15;
  }
}
