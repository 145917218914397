@keyframes slideUp {
  from {
    bottom: -5rem;
  }
  to {
    bottom: 0;
  }
}

.bottom {
  position: fixed;
  bottom: -5rem;
  width: 100%;
  z-index: 100;
  animation: slideUp 0.3s ease-in-out forwards;
}
