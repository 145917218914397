.container {
  margin: var(--spacing-huge) auto;
  max-width: 1440px;
}

.text {
  line-height: 2.25rem;
  padding: var(--spacing-tiny);
  text-align: center;
}

.hashtag {
  color: var(--layers-onSurfaceHighEmph);
}

.hashtag:hover {
  cursor: pointer;
}

@media (max-width: 850px) {
  .text {
    line-height: unset;
    text-align: left;
  }
}
