.modalContainer {
  padding: 1.5rem;
  background-color: var(--layers-grayscale5);
  text-align: center;
  border-radius: 0.5rem;
}

.modalContent {
  width: 100%;
  max-width: 464px;
  border-radius: 0.5rem;
  background-color: var(--layers-surface);
}

.modalContent::-webkit-scrollbar {
  display: none;
}
