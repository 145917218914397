.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--dialog-overlay-color); /* Semi-transparent background */
  z-index: 15; /* Make sure it is above other elements */
  pointer-events: all;
}

.overlayAppBanner {
  z-index: 50;
}
